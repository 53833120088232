/* eslint-disable max-len */
/* eslint-disable no-console */
import React, {
  FC, useContext, useEffect, useRef, useState,
} from 'react';
import { navigate, PageProps } from 'gatsby';
import { useLocation } from '@reach/router';
import QueryString from 'query-string';
import { Helmet } from 'react-helmet';
import CarDigest from '@/common/core/model/carDigest';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import API from '@/common/util/api';
import URI from '@/newcar/util/uri';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import { getClientId } from '@/common/util/gtag';
import { GTM } from '@/common/util/gtm';
import SearchCarStoreContext from '@/newcar/ui/common/context/searchCarStoreContext';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import ContractStoreContext from '@/newcar/ui/common/context/ContractStoreContext';
import { Header } from '@/newcar/ui/contract/components';
import Show from '@/newcar/ui/molecule/Show';
import loading from '@/newcar/ui/lineup/img/loading.svg';

import * as styles from './index.module.styl';

type Props = {
  digest: CarDigest;
  vehicleType: VehicleTypes;
};

// GA4クライアントIDを取得する
async function getGa4ClientId() {
  try {
    return await getClientId();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    return '';
  }
}

const Page: FC<PageProps<{}, Props>> = ({ pageContext }) => {
  const { digest, vehicleType } = pageContext;
  const location = useLocation();
  const query = QueryString.parse(location.search);
  const searchCarStore = useContext(SearchCarStoreContext);
  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const contractStore = useContext(ContractStoreContext);
  const [error, setError] = useState(false);

  // 最安値のグレード
  const minPriceGrade = vehicleType.vehicleGrades
    ?.filter((vehicleGrade: VehicleGrade) => vehicleGrade.leasingFee132)
    .reduce((a, b) => (a.leasingFee132 && b.leasingFee132 && a.leasingFee132 > b.leasingFee132 ? b : a));
  // ボディーカラーID
  const bodyColorId = minPriceGrade?.bodyColors ? minPriceGrade.bodyColors[0].id : '';

  if (minPriceGrade) {
    // 最安のシミュレーションをセット
    searchCarStore.setAllCarDigests([digest]);
    searchCarStore.setAllCarDetails([vehicleType]);
    simulationManagerStore.changeId(digest.id__normalized.toString());
    simulationManagerStore.startSimulation(digest.id__normalized.toString());
    simulationManagerStore.setGrade(minPriceGrade);
    simulationManagerStore.changeCarNavi('100001'); // カーナビOFF
    simulationManagerStore.changeWantCar('no'); // もらえるオプションOFF
    simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
  } else {
    console.error('グレードの取得に失敗しました。');
    setError(true);
  }

  /* Refs */
  const ref = {
    gclid: useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>,
    yclid: useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>,
  };

  useEffect(() => {
    if (!query) {
      return;
    }
    if (query.key) {
      // 再申込み処理
      (async () => {
        // 流入元取得
        await contractStore.retrieveCVSource();
        // UTMパラメータ取得
        await contractStore.retrieveUtmParams();

        const data = {
          reapplication_key: query.key,
          reapplication_price: simulationManagerStore.nowSelectPrice,
          months: 132, // 11年
          vehicle_grade_id: minPriceGrade?.id,
          vehicle_type_id: vehicleType.id,
          maker_id: digest.makerId,
          grade_id: minPriceGrade?.id,
          master_number: digest.masterNumber,
          options: [bodyColorId, '', '', '', '', '', ''],
          cv_source: contractStore.getContract.cvSource || '',
          utm_source: contractStore.getContract.utmSource || '',
          utm_medium: contractStore.getContract.utmMedium || '',
          utm_campaign: contractStore.getContract.utmCampaign || '',
          previous_url: contractStore.getContract.previousUrl || '',
          line_id: contractStore.getContract.lineId || '',
          gclid: ref.gclid?.current.value,
          yclid: ref.yclid?.current.value,
          ga4_client_id: await getGa4ClientId(),
        };

        try {
          // API実行
          const response = await API.post(URI.APIEndPoints.Application, data);
          if (!response?.data?.reservationId) {
            throw new Error('API failed.');
          }
          GTM.dataLayer.push({
            userId: response.data.reservationId || null,
            reservationId: response.data.reservationId,
          });
          contractStore.setContract({ key: 'reservationId', value: response.data.reservationId });
          contractStore.setContract({ key: 'complete', value: true });
          navigate(`${NEWCAR_DEFINE.PATH.FORM_COMPLETION}?res=${response.data.reservationId}`);
        } catch (e) {
          console.error('再申込みに失敗しました。', e);
          setError(true);
        }
      })();
    } else {
      console.error('パラメータが不正です。');
      setError(true);
    }
  }, [query]);

  const goToForm = () => {
    navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <div className={styles.page}>
        <Show
          when={!error}
          fallback={(
            <div>
              <p>再申込みのデータに不備がありました。</p>
              <p>申込みフォームの入力をお願いいたします。</p>
              <div className="has-text-centered is-margin-top-6">
                <button type="button" className={styles.formButton} onClick={goToForm}>申込みフォーム</button>
              </div>
            </div>
          )}
        >
          <div className="has-text-centered is-padding-bottom-6">
            <p>しばらくお待ちください。</p>
            <img src={loading} alt="loading" width="100" className="is-margin-top-6" />
          </div>
        </Show>
      </div>
      {/* Google広告 GCLID用フィールド ※削除厳禁！ */}
      <input type="hidden" name="gclid_field" id="gclid_field" ref={ref.gclid} />
      <input type="hidden" name="yclid_field" id="yclid_field" ref={ref.yclid} />
    </>
  );
};

export default Page;
