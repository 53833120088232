const GA4_ID = 'G-VG421T2K6B';
// GA4クライアントID取得時のタイムアウト（ミリ秒）
const TIMEOUT_MILLI_SECONDS = 1000;

declare global {
  interface Window {
    gtag(command: string, target: string, field: string, callback: Function): void;
  }
}

// gtag.jsを使用して GA4 client_id を取得
export const getClientId: Function = () => new Promise(
  (resolve, reject) => {
    // タイムアウトを設定
    setTimeout(() => reject(new Error(`Failed to retrieve Ga4Client even after ${TIMEOUT_MILLI_SECONDS} milliseconds.`)), TIMEOUT_MILLI_SECONDS);
    // client_idを取得
    window.gtag('get', GA4_ID, 'client_id', resolve);
  },
);
